import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BrukerService } from '../../environments/bruker.service';
import { ConfirmdialogComponent } from '../confirmdialog/confirmdialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-endre-opplysninger',
  templateUrl: './endre-opplysninger.component.html',
  styleUrls: ['./endre-opplysninger.component.css']
})
export class EndreOpplysningerComponent implements OnInit {

  constructor(private service: BrukerService, private fb: FormBuilder, private snackBar: MatSnackBar) { }


  form = this.fb.group({
    fornavn: ['', Validators.required],
    etternavn: ['', Validators.required],
    epost: ['', Validators.required]
  })

  openSnackBar(message: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-white'];
    config.duration = 4000;
    this.snackBar.open(message, undefined, config);
  }

  ngOnInit() {
    this.service.getBrukerprofil().subscribe(
      (res: any) => {
        this.form.patchValue({ fornavn: res.fornavn });
        this.form.patchValue({ etternavn: res.etternavn });
        this.form.patchValue({ epost: res.email });
      },
      err => {
        console.log(err);
      }
    )
  }

  lagre() {
    //if (this.form.touched) {
      var body = {
        Fornavn: this.form.value.fornavn,
        Etternavn: this.form.value.etternavn,
        Epost: this.form.value.epost,
      }

      this.service.endreBrukerprofil(body).subscribe(
        (res: any) => {
          this.openSnackBar(res);
          console.log(res);
        },
        err => {
          this.openSnackBar(err);
        })
    //}
  }

  FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);
}
