import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BrukerService } from '../../environments/bruker.service';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-endre-passord',
  templateUrl: './endre-passord.component.html',
  styleUrls: ['./endre-passord.component.css']
})
export class EndrePassordComponent implements OnInit {

  constructor(private service: BrukerService, private fb: FormBuilder, private snackBar: MatSnackBar) { }

  ngOnInit() {

  }

  formNyttPassord = this.fb.group({
    gammeltPassord: ['', Validators.required],
    nyttPassord: ['', [Validators.required, Validators.minLength(8)]],
    gjentaPassord: ['', [Validators.required, Validators.minLength(8)]]
    },
    { validator: this.comparePasswords }
  )

  FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);

  openSnackBar(message: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = ['bold-font-white'];
    config.duration = 4000;
    this.snackBar.open(message, undefined, config);
  }

  endrePassord() {
    var body = {
      GammeltPassord: this.formNyttPassord.value.gammeltPassord,
      NyttPassord: this.formNyttPassord.value.nyttPassord
    }

    //this.openSnackBar("Endringer ikke lagret, ettersom dette er en testversjon");
    this.service.endrePassord(body).subscribe(
      (res: any) => {
        this.openSnackBar(res);
        console.log(res);
      },
      err => {
        this.openSnackBar("Feil: " + err?.error);
      })
  }


  comparePasswords(fb2: FormGroup) {
    let confirmPswrdCtrl = fb2.get('gjentaPassord');
    //passwordMismatch
    //confirmPswrdCtrl.errors={passwordMismatch:true}
    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb2.get('nyttPassord').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }

}
