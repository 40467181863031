import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EksporterService {

  constructor(private http: HttpClient) { }


  getEposter() {
    return this.http.get(environment.apiBaseURI + '/publiser');
  }

  endreLeiboblaAktiv(value : boolean) {
    return this.http.put(environment.apiBaseURI + '/publiser/endreLeiboblaAktiv?value='+value, null);
  }

  getTransportplan(prosjektnummer : string, dag: string) {
    return this.http.get(environment.apiBaseURI + '/publiser/transportplan/'+prosjektnummer+"/"+dag);
  }

  getStorskjerm(dag: number) {
    return this.http.get(environment.apiBaseURI + '/publiser/'+ dag);
  }

  endreProsjektnummer(value : string) {
    return this.http.put(environment.apiBaseURI + '/publiser/endreProsjektnummer?prosjektnummer='+value, null)
  }
}
