import { Component, OnInit, Input, ChangeDetectorRef, HostListener, Output, EventEmitter } from '@angular/core';
import { AktivitetMTid } from './AktivitetMTid';
import { Reisebolk } from './Reisebolk';
import { PlanService } from '../../environments/plan.service';
import { timer } from 'rxjs';
import { map } from 'jquery';
import { cloneDeep } from 'lodash';
import {} from 'googlemaps';
import { Reisetid } from './Reisetid';
declare module 'googlemaps';

@Component({
  selector: 'dag-plan-component',
  templateUrl: './dag-plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class DagPlanComponent implements OnInit {

  constructor(private service: PlanService, public cd: ChangeDetectorRef) {
  }

  B1utR1: AktivitetMTid[];
  B1utR2: AktivitetMTid[];
  B1innR1: AktivitetMTid[];
  B1innR2: AktivitetMTid[];

  @Input() dag: number;
  @Input() AktivitetMTidsData: AktivitetMTid[];
  @Input() avreiseEn: string;
  @Input() avreiseTo: string;
  @Input() reisetider: Reisetid[];
  @Input() leir: any;
  @Input() AvgangForDenneDag: any[];
  @Input() LokaleIngenTransportId: number;


  tilbakeTidR2T1: number = 0;
  tilbakeTidR1T1: number = 0;

  public dagsPlanerDag1: Reisebolk[] = [];
  public innAlle: Reisebolk[] = [];
  public bundetVerdi = "-";

  visKopierInfo = true;

  public tpmArray: any[] = [];
  public AktivitetMTid: any[] = [];

  public reisebolk: any[] = [];

  public test: any;
  ikkeVisIgjen = false

  public GodkjenteReisebolkerUt = [];
  showSpinner = true;

  totaltReisende: number = 0;
  totaltAntallSeter : number = 0;
  fyllingsgrad: string = "";

  /*public utreise: Reisebolk = { r1: [], r2: [], kapasitet: 0 };
  public utreise2: Reisebolk = { r1: [], r2: [], kapasitet: 0 };

  public innreise1: Reisebolk = { r1: [], r2: [], kapasitet: 0 };
  public innreise2: Reisebolk = { r1: [], r2: [], kapasitet: 0 };*/

  public changeTilbakeTidR2T1(val : any){
    this.tilbakeTidR2T1 = val;
  }

  public changeTilbakeTidR1T1(val : any){
    this.tilbakeTidR1T1 = val;
  }

  public lukkInfoOmKopier(){

    this.visKopierInfo = false;
    if(this.ikkeVisIgjen == true){
      const itemNavn = "ikkeVisIgjen_d"+this.dag+"_"+this.leir.id
      localStorage.setItem(itemNavn, "true")
    }
  }

  endreSjekkboksInfoOmKopier(event){
    this.ikkeVisIgjen = event.target.checked

  }

  public async lagreEndringer(id1: string, id2: string) {

    let reiseFra: Reisebolk;
    let reiseTil: Reisebolk;

    this.dagsPlanerDag1.forEach((obj: Reisebolk) => {
      if (id1 == obj.id.toString()) {
        this.service.putPlan(obj).subscribe(
          //error => console.error("1 - kunne ikke: ", error)
        );

      }
    });

    timer(1000).subscribe(x => {
      this.dagsPlanerDag1.forEach((obj: Reisebolk) => {
        if (id2 == obj.id.toString()) {
          this.service.putPlan(obj).subscribe(
            //error => console.error("1 - kunne ikke: ", error)
          );
        }
      });
    })
  }

  /*

  Kommentert ut 21.01.22, da den mest sannsynlig ikke brukes til noe
  public async finnAktMTid(id1: string, id2: string) {

    let reiseFra: AktivitetMTid;
    let reiseTil: AktivitetMTid;

    /*this.dagsPlanerDag1.forEach((obj: Reisebolk) => {
      if (id1 == obj.id.toString()) {
        obj.t1r1.forEach((obj: any) => {

        })
      };
    });

    timer(0).subscribe(x => {
      this.dagsPlanerDag1.forEach((obj: Reisebolk) => {
        if (id2 === obj.id.toString()) {
          this.service.putPlan(obj).subscribe(
            //error => console.error("1 - kunne ikke: ", error)
          );
        }
      });
    })
  }*/

  ingenTpm = false;


  ngOnInit() {


    const itemNavn = "ikkeVisIgjen_d"+this.dag+"_"+this.leir.id

    if(localStorage.getItem(itemNavn) === "true"){
      this.visKopierInfo = false;
    }

    if(this.AktivitetMTidsData.length == 0){
      this.ingenTpm = true;
      this.showSpinner = false;
    }
    else{



      this.AvgangForDenneDag

   /* this.service.getAvgang(this.dag).subscribe(res1 => {
      (res1 as [])*/.forEach((avg: any) => {


        let dagsplanforEnBuss: Reisebolk = {
          dag: avg.dag,
          id: avg.id,
          t1r1: [],
          t1r2: [],
          t2r1: [],
          t2r2: [],
          kapasitet: avg.bussen["kapasitetD" + this.dag],
          bussnr: avg.bussen.bussnr,
          selskap: avg.bussen.selskap
        };

        //Oppdater totalt kapasitet for denne dagen
        this.totaltAntallSeter = this.totaltAntallSeter + (dagsplanforEnBuss.kapasitet * 2);

        var bredde = this.sjekkBredde();
        this.AktivitetMTidsData.forEach((aktMTid: any) => {

          if (aktMTid.avgang.id === avg.id) {

            //AVG T1R1
            if (aktMTid.turRunde === 11) {
              //oppdate antall
              this.totaltReisende = this.totaltReisende + aktMTid.aktivitet.antallDeltakere + aktMTid.aktivitet.antallLedere;

              let reise: AktivitetMTid = {
                endePaaRute : aktMTid.endePaaRute,
                index: aktMTid.index,
                kortNavn: this.forkortString(aktMTid.aktivitet.navn, bredde),
                reisebolkid: aktMTid.id,
                aktivitet: aktMTid.aktivitet,
                rundeTall: 1,
                turRunde: 11,
                antall: aktMTid.aktivitet.antallDeltakere + aktMTid.aktivitet.antallLedere,
                id: aktMTid.id,
                navn: aktMTid.aktivitet.navn,
                avreise: (aktMTid.avreise == null ? this.avreiseEn : aktMTid.avreise),
                auto: (aktMTid.avreise == null ? true : false),
                farge: (aktMTid.endePaaRute == true) ? "#9fa09f" : aktMTid.aktivitet["lokaleD" + this.dag].farge,
                lat: aktMTid.aktivitet["lokaleD" + this.dag].lat,
                lng: aktMTid.aktivitet["lokaleD" + this.dag].lng,
                info: aktMTid.aktivitet["lokaleD" + this.dag].navn + "\n" + aktMTid.aktivitet["lokaleD" + this.dag].gateadresse + ", " + aktMTid.aktivitet["lokaleD" + this.dag].postnr + " " + aktMTid.aktivitet["lokaleD" + this.dag].poststed
              }
              dagsplanforEnBuss.t1r1.push(reise);
            }

            //AVG T1R2
            if (aktMTid.turRunde === 12) {
              //oppdate antall
              this.totaltReisende = this.totaltReisende + aktMTid.aktivitet.antallDeltakere + aktMTid.aktivitet.antallLedere;

              let reise: AktivitetMTid = {
                endePaaRute : aktMTid.endePaaRute,
                index: aktMTid.index,
                reisebolkid: aktMTid.id,
                aktivitet: aktMTid.aktivitet,
                rundeTall: 1,
                turRunde: 12,
                kortNavn: this.forkortString(aktMTid.aktivitet.navn, bredde),
                antall: aktMTid.aktivitet.antallDeltakere + aktMTid.aktivitet.antallLedere,
                id: aktMTid.id,
                navn: aktMTid.aktivitet.navn,
                avreise: (aktMTid.avreise == null ? this.avreiseTo : aktMTid.avreise),
                auto: (aktMTid.avreise == null ? true : false),
                farge: (aktMTid.endePaaRute == true) ? "#9fa09f" : aktMTid.aktivitet["lokaleD" + this.dag].farge,
                lat: aktMTid.aktivitet["lokaleD" + this.dag].lat,
                lng: aktMTid.aktivitet["lokaleD" + this.dag].lng,
                info: aktMTid.aktivitet["lokaleD" + this.dag].navn + "\n" + aktMTid.aktivitet["lokaleD" + this.dag].gateadresse + ", " + aktMTid.aktivitet["lokaleD" + this.dag].postnr + " " + aktMTid.aktivitet["lokaleD" + this.dag].poststed
              }
              dagsplanforEnBuss.t1r2.push(reise);
            }

            //RETUR
            //AVG T2R1
            if (aktMTid.turRunde === 21) {
              let reise: AktivitetMTid = {
                endePaaRute : aktMTid.endePaaRute,
                index: aktMTid.index,
                reisebolkid: aktMTid.id,
                aktivitet: aktMTid.aktivitet,
                rundeTall: 2,
                turRunde: 21,
                antall: aktMTid.aktivitet.antallDeltakere + aktMTid.aktivitet.antallLedere,
                id: aktMTid.id,
                navn: aktMTid.aktivitet.navn,
                avreise: aktMTid.avreise,
                auto: (aktMTid.avreise == null ? true : false),
                kortNavn: this.forkortString(aktMTid.aktivitet.navn, bredde),
                farge: aktMTid.aktivitet["lokaleD" + this.dag].farge,
                lat: aktMTid.aktivitet["lokaleD" + this.dag].lat,
                lng: aktMTid.aktivitet["lokaleD" + this.dag].lng,
                info: aktMTid.aktivitet["lokaleD" + this.dag].navn + "\n" + aktMTid.aktivitet["lokaleD" + this.dag].gateadresse + ", " + aktMTid.aktivitet["lokaleD" + this.dag].postnr + " " + aktMTid.aktivitet["lokaleD" + this.dag].poststed
              }
              dagsplanforEnBuss.t2r1.push(reise);
            }


            //AVG T2R2
            if (aktMTid.turRunde === 22) {
              let reise: AktivitetMTid = {
                endePaaRute : aktMTid.endePaaRute,
                index: aktMTid.index,
                kortNavn: this.forkortString(aktMTid.aktivitet.navn, bredde),
                reisebolkid: aktMTid.id,
                aktivitet: aktMTid.aktivitet,
                rundeTall: 2,
                turRunde: 22,
                antall: aktMTid.aktivitet.antallDeltakere + aktMTid.aktivitet.antallLedere,
                id: aktMTid.id,
                navn: aktMTid.aktivitet.navn,
                avreise: aktMTid.avreise,
                auto: (aktMTid.avreise == null ? true : false),
                farge: aktMTid.aktivitet["lokaleD" + this.dag].farge,
                lat: aktMTid.aktivitet["lokaleD" + this.dag].lat,
                lng: aktMTid.aktivitet["lokaleD" + this.dag].lng,
                info: aktMTid.aktivitet["lokaleD" + this.dag].navn + "\n" + aktMTid.aktivitet["lokaleD" + this.dag].gateadresse + ", " + aktMTid.aktivitet["lokaleD" + this.dag].postnr + " " + aktMTid.aktivitet["lokaleD" + this.dag].poststed
              }
              dagsplanforEnBuss.t2r2.push(reise);
            }
          }
        })
        this.dagsPlanerDag1.push(dagsplanforEnBuss);
        this.kalkReisetid()
        this.showSpinner = false;




      })

  }

  //Bergene fyllingsgrad
  this.fyllingsgrad = ((this.totaltReisende / this.totaltAntallSeter) * 100).toFixed(1);

  }


  lagre() {

  }

  //Returner hvor mye som skal kortes
  sjekkBredde() {
    var lengde: number = 0;
    var bredde: number = window.innerWidth;
    console.log(bredde)

    //større enn 1500
    if (bredde > 1350) {
      return 26
    }
    else if (bredde > 1250) {
      return 23
    }
    //mellom 1300 og 1500
   /*else if (bredde > 1100) {
      return 22
    }
     else if (bredde > 1000) {
      return 16
    }/*
    //mellom 1299 og 1100
    else if (bredde > 900) {
      return 13
    }*/
    /*else if (bredde > 800) {
      return 10
    }
    else if (bredde > 700) {
      return 7
    /*}
    else if (bredde > 550) {
      return 4
    }
    */
    else {
      return 20
    }
  }

  forkortString(navn: string, lengde: number) {
    if (navn.length > lengde + 2) {
      //var length = 15;
      navn.trim();
      var kortNavn = navn.substring(0, lengde) + "..";
      return kortNavn;
    }
    return navn;
  }

  kalkReisetid() {
    this.dagsPlanerDag1.forEach((obj: Reisebolk) => {
      obj.t1r1.forEach((x: AktivitetMTid) => {

      })
    })


    let listOrgin : any[] = [];
    let listDest : any[] = [];

    for (let i = 0; i < (this.dagsPlanerDag1[0].t1r1.length-1); i++) {
      const origin = { lat: this.dagsPlanerDag1[0].t1r1[i].lat, lng: this.dagsPlanerDag1[0].t1r1[i].lng };

      const dest = { lat: this.dagsPlanerDag1[0].t1r1[i + 1].lat, lng: this.dagsPlanerDag1[0].t1r1[i+1].lng };

      listOrgin.push(origin);
      listDest.push(dest);

    }

    // initialize services
    const geocoder = new google.maps.Geocoder();
    const service = new google.maps.DistanceMatrixService();

    // build request
    const origin1 = { lat: 61.12, lng: 10.45 };
    const origin2 = { lat: 61.23, lng: 10.44 };
    const origin3 = { lat: 61.24, lng: 10.34 };

    /*const request = {
      origins: listOrgin,
      destinations: listDest,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    }
    service.getDistanceMatrix({
      origins: listOrgin,
      destinations: listDest,
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    },
      function (response, status) {
        if (status === 'OK') {
          console.log(response)
          this.bergnTid(response);
        } else {
          console.log(response);
        }
      })*/
  }




  bergenTid(respons: any) {



  }

  bergenTotaltSeterHverVeiOgTransporttrengende(){

    //Bergener totalt antall reisende
    this.AktivitetMTidsData.forEach((aktMTid : AktivitetMTid) =>
      {
        if(aktMTid.turRunde == 11 || aktMTid.turRunde == 12){
          if(aktMTid.avgang.dag == 1){

          }

        }
      })



  }
}

export class Avgang {
  id: number;
  bussen: any;
  dagRetningRunde: number;
  ledigePlasser: number;
  avreisegruppe: AktivitetMTid[] = [];
  dag : number;
}

export class LatLng {
  lat: string;
  lng: string;
}




/*export class Avreisegruppe {
  id: number;
  bussen:

}*/
