
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { PlanService } from '../../../environments/plan.service';
import { AktivitetMTid } from '../../plan/AktivitetMTid'
import { Reisebolk as Buss } from '../../plan/Reisebolk'

import { timer } from 'rxjs';
import { map } from 'jquery';
import { cloneDeep } from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { Lokale, Reisetid } from '../../plan/Reisetid';
import { Avgang } from '../../plan/dag-plan.component';
import { info } from 'console';


@Component({
  selector: 'app-sjoforplan',
  templateUrl: './sjoforplan.component.html',
  styleUrls: ['./sjoforplan.component.css']
})


export class SjoforplanComponent implements OnInit {


  webmodus = true;
  print = "false";
  dato = "";
  leir: any = null;
  leirid: any = 0;
  teller = 0;
  valgtDag = false;
  dag: any = 1;
  laster = true;
  ingenValgt = true;
  adresse = "Laster.."

  B1utR1: AktivitetMTid[];
  B1utR2: AktivitetMTid[];
  B1innR1: AktivitetMTid[];
  B1innR2: AktivitetMTid[];
  reisetider: Reisetid[] = [];
  avreiseR1 : string = "10:00";
  avreiseR2 : string = "10:45";
  leirenslokale: Lokale;
  stopDelay : number = 240; // 4 min delay

  ingenAkt: string = "";

  //@Input() dag: number;
  AktivitetMTidsData: AktivitetMTid[];

  public dagsPlanerDag1: Buss[] = [];

  googleDirectionUrlTur1 = "";
  googleDirectionUrlTur2 = "";


  public genererGoogleMapsDiractionUrls(aktMTiderR1_inn : AktivitetMTid[], aktMTiderR2_inn : AktivitetMTid[], opneNyFane : boolean){

    if((aktMTiderR1_inn.length + aktMTiderR2_inn.length) === 0){
        return "ingen";
    }

    var alleAktSammen : AktivitetMTid[] = [...aktMTiderR1_inn];
    alleAktSammen = alleAktSammen.concat(aktMTiderR2_inn);



    let alleEndePaaRute = true;
    alleAktSammen.forEach((avg : any) => {
        if(avg.endePaaRute === false){
            alleEndePaaRute = false
        }
    })
    if(alleEndePaaRute){
        return "ingen";
    }





    let utenDuplikat : AktivitetMTid[] = []

    let firstLokale: any = {};
    let lastLokale: any = {};
    let ikkeTaMedSisteR1 = 0;
    let startR2Fra = 0;


    //console.log(alleAktSammen[alleAktSammen.length-1]);

    //fjerne like etterhverandre
    for(let i = 0; i < alleAktSammen.length-1; i++) {
        //hvis ulike neste, addes den
        if(alleAktSammen[i].aktivitet.lokale.id !== alleAktSammen[i+1].aktivitet.lokale.id){
            //console.log(alleAktSammen[i].aktivitet.lokale.navn+" legge til, da ulik "+alleAktSammen[i+1].aktivitet.lokale.navn)
            utenDuplikat.push(alleAktSammen[i]);
           /* if(i === alleAktSammen.length-2){
                console.log("legger også til "+alleAktSammen[i+1].aktivitet.lokale.navn)
                utenDuplikat.push(alleAktSammen[i+1]);
                //utenDuplikat.push(alleAktSammen[i+1]);
            }*/
        }
    }

    //console.log(utenDuplikat);

    if(utenDuplikat.length === 0){
        console.log(alleAktSammen)
    }
    let url = "https://www.google.com/maps/dir/?api=1&travelmode=driving&origin="+utenDuplikat[0].aktivitet.lokale.lat+","+utenDuplikat[0].aktivitet.lokale.lng + "&waypoints="


    for(let i = 1; i < utenDuplikat.length; i++){
        url = url + utenDuplikat[i].aktivitet.lokale.lat + "," + utenDuplikat[i].aktivitet.lokale.lng + "|"
    }

    url = url + "&destination="+alleAktSammen[alleAktSammen.length-1].aktivitet.lokale.lat+","+alleAktSammen[alleAktSammen.length-1].aktivitet.lokale.lng;

    if(opneNyFane){
        window.open(url, '_blank');
    }

    return url;

  }


  ngOnInit() {
    //this.leir.leirnavn = "Laster.."
    var url_string = window.location.href;
    var url = new URL(url_string);
    var dag = url.searchParams.get("dag");
    var leirid = url.searchParams.get("leirid");
    this.print = url.searchParams.get("print");

    this.dag = dag;
    this.leirid = leirid;

    //this.hentReisetid();

    this.behandleAvgang();
  }



  constructor(private service: PlanService, public cd: ChangeDetectorRef) {
  }



  beregnTid(runde : AktivitetMTid[]){
  //Unngå at hele systemet kræsjer hvis en tid er null

    if(runde.length > 0){
        if(runde[0].avreise == null){
        for(let i = 0; i < runde.length; i++){
            runde[i].avreise = "?";
        }
        return runde;
        }
    }


    //Går gjennom den nye listen
    for(let i = 0; i < runde.length-1; i++){

        //console.log(aktMTidItems_current[i].avreise+", "+aktMTidItems_current[i+1].avreise)
        //Hvis to like etter hverandre
        if(runde[i].aktivitet.lokale.id == runde[i+1].aktivitet.lokale.id){
            var a = runde[i].avreise.split(":")
            var fraSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
            runde[i+1].avreise = (new Date((fraSeconds) * 1000).toISOString().slice(11, 16))
        }
        //Hvis de ikke er like
        else{
            this.reisetider.forEach((obj) => {
                //Hvis i sitt lokale er likt i-sitt neste lokale
                if((runde[i].aktivitet.lokale.id === obj.fraLokaleId && runde[i+1].aktivitet.lokale.id === obj.tilLokaleId) || (runde[i].aktivitet.lokale.id === obj.tilLokaleId && runde[i+1].aktivitet.lokale.id === obj.fraLokaleId)){
                    var a = runde[i].avreise.split(":")
                    var fraSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
                    //console.log(this.runde[i+1])
                    if(runde[i+1].auto == true){
                        runde[i+1].avreise = (new Date((fraSeconds + obj.tidMs + this.stopDelay) * 1000).toISOString().slice(11, 16))
                    }
                }
            })
        }


        }
        return runde
}



  avganger : any[] = [];

  reconstructAktMTid(obj : any, dagToTakOut : number){

    obj.aktivitetMTids.forEach((akt : AktivitetMTid) => {
        akt.aktivitet["lokale"] = akt.aktivitet["lokaleD"+dagToTakOut];
        akt["antall"] = akt.aktivitet.antallDeltakere + akt.aktivitet.antallLedere,
        akt["auto"] = (akt.avreise == null) ? true : false;
        akt["farge"] = (akt.aktivitet.lokale.lokaleTilIngenTransport === true) ? 'green' : 'black'

        if(akt.turRunde === 11){
            obj.t1r1.push(akt);
        }
        if(akt.turRunde === 12){
            obj.t1r2.push(akt);
        }
        if(akt.turRunde === 21){
            obj.t2r1.push(akt);
        }
        if(akt.turRunde === 22){
            obj.t2r2.push(akt);
        }


        }
    )

    this.sorter(obj.t1r1);
    this.sorter(obj.t1r2);
    this.sorter(obj.t2r1);
    this.sorter(obj.t2r2);

    this.leggTilEndepunktRetur(obj.t2r1, 21);
    this.leggTilEndepunktRetur(obj.t2r2, 22);


    obj.t1r1 = this.beregnTid(obj.t1r1);
    obj.t1r2 = this.beregnTid(obj.t1r2);
    obj.t2r1 = this.beregnTid(obj.t2r1);
    obj.t2r2 = this.beregnTid(obj.t2r2);

    //console.log(aktMTid[0].aktivitet.lokale)


  }

  tilUtskriftversjon(){
    this.webmodus = false;
    this.addGoogleMapsURLs();
  }

  tilWebmodus(){
    this.webmodus = true;
  }

  skrivUt(){
    window.print();
  }


  addGoogleMapsURLs(){
    let avgCopy = [...this.avganger]
    avgCopy.forEach((avg => {
        avg.googleUrlT1 = this.genererGoogleMapsDiractionUrls(avg.t1r1, avg.t1r2, false)
        avg.googleUrlT2 = this.genererGoogleMapsDiractionUrls(avg.t2r1, avg.t2r2, false)
    }))

    this.avganger = avgCopy;
  }


  behandleAvgang(){


    this.service.getAktivitetMTidsMLeirId(this.leirid, this.dag).subscribe((res : any) => {
        //this.AktivitetMTidsData = res.aktivitetMTid as [];
        this.reisetider = res.reisetider as [];
        //if ((res as []).length != 0) {
        this.leir = res.leir;

        //set dato for dagen
        const dato_fra: Date = new Date(res.leir.datoFra);

        let day = (60 * 60 * 24 * 1000)
        let datoDag1: Date = new Date(dato_fra.getTime() + (day * this.dag));
        this.dato = datoDag1.getDate()+"."+(datoDag1.getMonth()+1);

        this.adresse = res.adresse;
        this.leirenslokale = res.leirenslokale;


        //lagre alle avganger
        let avgangObj: any[] = [];


        (res.avgang as []).forEach((obj : any) => {
            obj["bussen"] = obj.bussen;
            obj["dag"] = obj.dag;
            obj["id"] = obj.id;
            obj["avgangTilIngenTransport"] = obj.avgangTilIngenTransport,
            obj["t1r1"] = [],
            obj["t1r2"] = [],
            obj["t2r1"] = [],
            obj["t2r2"] = [],
            obj["googleUrlT1"] = "",
            obj["googleUrlT2"] = ""

            this.reconstructAktMTid(obj, this.dag)

        })


        this.laster = false;
        this.avganger = (res.avgang as []);
    })


  }


  leggTilEndepunktRetur(enTr : AktivitetMTid[], tr : number){

    if(enTr.length > 0){
      let akt: any = {};

      akt.navn = "Tilbake på leirområde";
      akt.lokale = this.leirenslokale;

      //akt.aktivitet.navn = "Hei"

      let reise: AktivitetMTid = {
        index: 5000000,
        lat: this.leirenslokale.lat,
        lng: this.leirenslokale.lng,
        kortNavn: "Ankomst leir",
        reisebolkid: -1,
        auto: true,
        aktivitet: akt,
        rundeTall: 2,
        turRunde: tr,
        antall: 0,
        id: -1,
        navn: "Ankomst leir",
        avreise: null,
        farge: "green",
        info: ""
      }

      enTr.push(reise);

    }
  }


  sorter(liste: AktivitetMTid[]) {
    liste.sort(function compare(a, b) {
      if (a.index < b.index) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      return 0;
    })

    return true;

  }

}


