


import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatSliderModule } from '@angular/material/slider';
import { AppComponent } from './app.component';
//import { AccordionModule } from 'ngx-bootstrap/accordion';
import { QRCodeModule } from 'angularx-qrcode';
//Google maps


//Sider
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HjelpComponent } from './hjelp/hjelp.component';
import { LokalerComponent } from './lokaler/lokaler.component';
import { NyttLokaleComponent } from './lokaler/nyttLokale.component';
import { NyTpm } from './transportmiddel/nyTpm/nyTpm.component';
import { TpmComponent } from './transportmiddel/tpm.component';
import { NyAktivitetComponent } from './aktivitet/nyAktivitet.component';
import { StorskjermComponent } from './eksporter/storskjerm.component';
import { DagPlanComponent } from "./plan/dag-plan.component";

//Egne
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {/* MatHorizontalStepper, */MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';



import { /*MatFormField, */MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { FooterComponent } from './footer/footer.component';
import { AktivitetComponent } from './aktivitet/aktivitet.component';
import { EksporterComponent } from './eksporter/eksporter.component';

import { MultilineTooltipComponent } from './multiline-tooltip/multiline-tooltip.component';
import { MasterPlanComponent } from './plan/master-plan.component';
import { ReisebolkComponent } from './plan/reisebolk.component';


import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import { OmProsjektetComponent } from './om-prosjektet/ompro.component';
import { RegistrerAdminComponent } from './registrer-admin/registrer-admin.component';
import { LoggInnComponent } from './logg-inn/logg-inn.component';
import { AuthGuard } from './auth/auth.guard';
import { MineSiderComponent } from './mine-sider/mine-sider.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { EndrePassordComponent } from './endre-passord/endre-passord.component';
import { EndreOpplysningerComponent } from './endre-opplysninger/endre-opplysninger.component';
import { SplittComponent } from './splitt/splitt.component';
import { CsvComponent } from './csv/csv.component';
import { SjoforplanComponent } from './sjoforplan/sjoforplan/sjoforplan.component';
import { SjoforrundeComponent } from './sjoforplan/sjoforrunde/sjoforrunde.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PubliserValgComponent } from './publiser-valg/publiser-valg.component';
import { AdresseComponent } from './lokaler/adresse/adresse.component';
import { AvreisetidComponent } from './avreisetid/avreisetid.component';
import { KopierDagComponent } from './kopier-dag/kopier-dag.component';
import { UtskriftComponent } from './sjoforplan/utskrift/utskrift.component';
import { UtskriftEnhetComponent } from './sjoforplan/utskrift/utskrift-enhet/utskrift-enhet.component';
import { VisTransportplanComponent } from './vis-transportplan/vis-transportplan.component';


const modules = [
  //Eget
  A11yModule,
  ReactiveFormsModule,
  CdkStepperModule,
  CdkTableModule,
  CdkTreeModule,

  ScrollingModule,

  //Angular forms
  FormsModule,

  //Angular material
  DragDropModule,
  PortalModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatNativeDateModule,
  MatRippleModule,
  MatDividerModule,
  MatTreeModule,

  //Kommentert ut fordi den brukes to steder
  BrowserModule,

  //MatHorizontalStepper,
  //BrowserAnimationsModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  //MatFormField,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatStepperModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule
]


@NgModule({


  declarations: [
    AppComponent,
    NavMenuComponent,
    HjelpComponent,
    LokalerComponent,
    NyttLokaleComponent,
    NyTpm,
    TpmComponent,
    FooterComponent,
    AktivitetComponent,
    NyAktivitetComponent,
    EksporterComponent,
    MultilineTooltipComponent,
    StorskjermComponent,
    MasterPlanComponent,

    DagPlanComponent,
    ReisebolkComponent,
    //Lagre ulagret

    ConfirmdialogComponent,
    OmProsjektetComponent,
    RegistrerAdminComponent,
    LoggInnComponent,
    MineSiderComponent,
    EndrePassordComponent,
    EndreOpplysningerComponent,
    SplittComponent,
    CsvComponent,
    SjoforplanComponent,
    SjoforrundeComponent,
    PubliserValgComponent,
    AdresseComponent,
    AvreisetidComponent,
    KopierDagComponent,
    UtskriftComponent,
    UtskriftEnhetComponent,
    VisTransportplanComponent,

  ],
  imports: [
    modules,
    QRCodeModule,
    //AccordionModule.forRoot(),

    //Google maps




    //Kommentert ut fordi den brukes to stede
    //BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: '', component: LoggInnComponent, pathMatch: 'full' },
      { path: 'hjelp', component: HjelpComponent, canActivate: [AuthGuard] },
      { path: 'lokaler', component: LokalerComponent, canActivate: [AuthGuard] },
      { path: 'nyttLokale', component: NyttLokaleComponent, canActivate: [AuthGuard] },
      { path: 'nyTpm', component: NyTpm, canActivate: [AuthGuard] },
      { path: 'tpm', component: TpmComponent, canActivate: [AuthGuard] },
      { path: 'aktivitet', component: AktivitetComponent, canActivate: [AuthGuard] },
      { path: 'nyAktivitet', component: NyAktivitetComponent, canActivate: [AuthGuard] },
      { path: 'eksporter', component: EksporterComponent, canActivate: [AuthGuard] },
      { path: 'plan', component: MasterPlanComponent, canActivate: [AuthGuard] },
      { path: 'registrer-admin', component: RegistrerAdminComponent, canActivate: [AuthGuard] },
      { path: 'mine-sider', component: MineSiderComponent, canActivate: [AuthGuard] },
      { path: 'plan-for-sjoforer', component: SjoforplanComponent },
      { path: 'transportplan', component: VisTransportplanComponent },
    ]),   //BrowserAnimationsModule
  ],
  exports: [
    //google,

    modules
  ],
  entryComponents: [ConfirmdialogComponent, OmProsjektetComponent, SplittComponent, CsvComponent, AdresseComponent],


  providers: [AuthGuard, NavMenuComponent,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
  /*
  entryComponents: [InputErrorStateMatcherExample],
  declarations: [InputErrorStateMatcherExample],
  bootstrap: [InputErrorStateMatcherExample],*/

  /*providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill'}
  }],*/
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }



/*
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));*/
