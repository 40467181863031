import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EksporterService } from 'src/environments/envireksporter/eksporter.service';
import { PlanService } from 'src/environments/plan.service';

interface Runde {
  runde: string;
  avreise: string;
  avganger: Avgang[];
}

interface Avgang {
  bussNr: number;

  //aktiviteter: AktivitetEntity[];
  aktiviteter: string[];
}

interface Aktivitet {
  navn: string;
  avreise: string;
}

interface AktivitetEntity {
  aktivitet: string;
  index: number;
  turRunde: number;
  avreise: string;
  bussNr: number;
}

@Component({
  selector: 'app-vis-transportplan',
  templateUrl: './vis-transportplan.component.html',
  styleUrls: ['./vis-transportplan.component.css']
})


export class VisTransportplanComponent implements OnInit {

  //comes from the url
  prosjektnummer: string;
  dag: string;


  private intervalId: any;

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  constructor(private service: EksporterService, private route: ActivatedRoute) { }

  runder : Runde[];
  //aktiviteter : AktivitetEntity[] = [];
  error : string;
  leir : {leirnavn : string, dato : string} | undefined = undefined;

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.prosjektnummer = params.get('prosjektnummer') ?? '';
      this.dag = params.get('dag') ?? '';
    });

    this.intervalId = setInterval(() => {
      this.fetchTransportplan();
    }, 30_000);

    //this.prosjektnummer = this.route.snapshot.paramMap.get('prosjektnummer');
    //this.dag = this.route.snapshot.paramMap.get('dag');
    this.fetchTransportplan();
  }

  fetchTransportplan() {



    this.service.getTransportplan(this.prosjektnummer, this.dag).toPromise().then((data: any) => {

      //console.log(new Date(data.metadata.datoFra));
      var date = new Date(data.metadata.dato);
      //date.setDate(date.getDate() + Number(this.dag));
      //const dato = new Date(new Date(data.metadata.datoFra).getDate() + ( 3600 * 1000 * 24 * Number(this.dag)))

      this.leir = {dato : date.toJSON().slice(8,10)+"."+date.toJSON().slice(5,7), leirnavn : data.metadata.leirnavn}

      const uniqueRunde: string[] = Array.from(new Set(data.aktiviteter.map((item: AktivitetEntity) => item.avreise)));
      const uniqueAvreise  : number[]= Array.from(new Set(data.aktiviteter.map((item: AktivitetEntity) => item.bussNr)));

      this.runder = uniqueRunde.map(avreise => {
        return {
          avreise: avreise ? avreise : "?",
          runde : data.aktiviteter.find((item: AktivitetEntity) => item.avreise === avreise)?.turRunde == 11 ? "Runde 1" : "Runde 2",
          avganger : uniqueAvreise.map(bussNr => {
            return {
              aktiviteter: data.aktiviteter.filter((item: AktivitetEntity) => item.avreise === avreise && item.bussNr === bussNr).sort((a: AktivitetEntity, b: AktivitetEntity) => a.index - b.index).map((item: AktivitetEntity) => item.aktivitet),
              bussNr: bussNr
            } as Avgang
          }).sort((a: Avgang, b: Avgang) => a.bussNr - b.bussNr)
        } as Runde}).sort((a: Runde, b: Runde) => {
            //sort on time
            const timeA = (new Date(`1970-01-01T${a.avreise}:00`)).getTime();
            const timeB = (new Date(`1970-01-01T${b.avreise}:00`)).getTime();
          return timeA - timeB;
        });
        }).catch((error) => {
          if(error?.error?.info){
            this.error = error.error.info;
            return;
          }
          this.error = "Kunne ikke hente transportplan akkurat nå. Prøv igjen senere.";

      });


  }

}
