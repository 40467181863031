import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BrukerService } from '../../environments/bruker.service';
import { PlanService } from '../../environments/plan.service';
import { Leir } from '../plan/master-plan.component';

@Component({
  selector: 'app-publiser-valg',
  templateUrl: './publiser-valg.component.html',
  styleUrls: ['./publiser-valg.component.css']
})
export class PubliserValgComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PubliserValgComponent>, private fb: FormBuilder, private http: BrukerService, private service: PlanService) { }


  dag1link = "";
  dag2link = "";
  dag3link = "";

  dagAutolinkDeltaker = "";
  dag1linkDeltaker = "";
  dag2linkDeltaker = "";
  dag3linkDeltaker = "";

  leirId = "";
  @Input() datoDag1: string = "Dag 1";
  @Input() datoDag2: string = "Dag 2";
  @Input() datoDag3: string = "Dag 3";


  formModel = this.fb.group({
    type: ["1", Validators.required],
    dag: ["1", Validators.required],
  })




  ngOnInit(): void {
    this.http.getBrukerprofil().subscribe((res: any) => {
      this.leirId = res.leir.id

      const prosjekt = res.leir.profundoProsjektnummer

      //set linker
      this.dag1link = window.location.origin + "/plan-for-sjoforer?dag=1&leirid=" + this.leirId;
      this.dag2link = window.location.origin + "/plan-for-sjoforer?dag=2&leirid=" + this.leirId;
      this.dag3link = window.location.origin + "/plan-for-sjoforer?dag=3&leirid=" + this.leirId;


      this.dagAutolinkDeltaker = window.location.origin + "/transportplan?dag=auto&prosjektnummer=" + prosjekt;
      this.dag1linkDeltaker = window.location.origin + "/transportplan?dag=1&prosjektnummer=" + prosjekt;
      this.dag2linkDeltaker = window.location.origin + "/transportplan?dag=2&prosjektnummer=" + prosjekt;
      this.dag3linkDeltaker = window.location.origin + "/transportplan?dag=3&prosjektnummer=" + prosjekt;
    });


  }


}
