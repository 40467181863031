import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BrukerService } from '../../environments/bruker.service';
import { ConfirmdialogComponent } from '../confirmdialog/confirmdialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mine-sider',
  templateUrl: './mine-sider.component.html',
  styleUrls: ['./mine-sider.component.css']
})
export class MineSiderComponent implements OnInit {

  constructor(private service: BrukerService, private fb: FormBuilder, private dialog: MatDialog, ) { }

  dialogRef: MatDialogRef<ConfirmdialogComponent>;

  slettet = "";

  ngOnInit() {
    //var token = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('token') })

  }

  slettBruker() {
    this.dialogRef = this.dialog.open(ConfirmdialogComponent, {
      disableClose: false,
      autoFocus: false,
      panelClass: 'small-width-dialog'
    });

    this.dialogRef.componentInstance.confirmMessage = "Er du sikker på at du vil slette din profil?";
    this.dialogRef.componentInstance.rodTekst = "VIKTIG: ";
    this.dialogRef.componentInstance.hovedtekst = "Denne handlingen kan ikke angres.";


    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        /*var epost = {
          epost: this.form.value.epost
        }*/

        this.service.slettBruker().subscribe(
          (res: any) => {
            console.log(res);
            this.slettet = "Logg ut for at endringene skal tre i kraft";
          },
          err => {
            console.log(err);
          })
      }
      this.dialogRef = null;
    });




  }

  FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
  ]);

}
